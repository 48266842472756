import { useCookies } from "react-cookie";
import Cookies from 'js-cookie';
import dayjs from "dayjs";
let cookie = require('cookie');

class AccountToken {
  AccountId: string | undefined;
  Role : string | undefined;
  exp: number | undefined;
}

function getAccountToken() : AccountToken | undefined {
  var token = getToken();
  if (typeof token === 'undefined' || token === '') {
    return undefined;
  }
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
  return JSON.parse(jsonPayload) as AccountToken;
}

export function getIsAdmin() : boolean {
  let accountToken =  getAccountToken();
  if(accountToken === undefined) { return false; }
  return accountToken.Role === 'admin';
}

export function getIsLoggedIn() : boolean {
  let accountToken =  getAccountToken();
  if(accountToken === undefined) { return false; }
  let expiry = dayjs.unix(accountToken.exp!);
  return expiry > dayjs();
}

export function getAccountId() : string {
  var token = getToken();
  if (typeof token === 'undefined' || token === '') {
    return '';
  }
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
  let accountToken = JSON.parse(jsonPayload) as AccountToken;
  return accountToken.AccountId!;
}

export function saveToken(token:string): void {
  Cookies.set('access_token', token, { expires: 300 } );
}

export function getToken() : string | undefined {
  return Cookies.get('access_token');
}

export function getEamil(): string | undefined {
  return Cookies.get('email');
}

export function saveEmail(email: string): void {
  Cookies.set('email', email, { expires: 720 } );
}

export function getDemandType() : string {
  const type = Cookies.get('demand_type');
  if (type) {
    return type;
  } else {
    return 'gantt';
  }
}

export function saveDemandType(type: string) {
  Cookies.set('demand_type', type, { expires: 100 });
}

export function getSpanType() : string {
  const type = Cookies.get('span_type');
  if (type) {
    return type;
  } else {
    return 'day';
  }
}

export function saveSpanType(type: string) {
  Cookies.set('span_type', type, { expires: 100 });
}

export function saveUncategorizedAreaVisible(value: boolean) {
  Cookies.set('uncategorizedAreaVisible', value.toString(), { expires: 100 });
}

export function getUncategorizedAreaVisible() : boolean {
  const value = Cookies.get('uncategorizedAreaVisible');
  return value === 'true';
}
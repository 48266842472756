import { FC, useState, useEffect } from "react";
import { postAsync } from "../../ApiHandler";
import { fetchAsync } from "../../ApiHandler";
import { BoardItem } from "../../Model/BoardItem";
import { SelectOption } from "../../Model/SelectOption";
import toast, { Toaster } from "react-hot-toast";
import { Account } from "../../Model/Account";
import { MemberHeader } from "../../parts/MemberHeader";
import { ToMinutesString } from "../../utils/DateUtil";
import { Helmet } from "react-helmet";
import { Circle } from "../../Model/Circle";
import Select from "react-select";
import { CircleMember } from "../../Model/CircleMember";

class AddCircleMemberParam {
  accountId: string = '';
  circleId: string = '';
}

export const CircleMemberListPage: FC = () => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const [formParam, setFormParam] = useState({} as AddCircleMemberParam);
  const [removeTarget, setRemoveTarget] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [version, setVersion] = useState(0);
  const [circleMemberList, setCircleMemberList] = useState([] as CircleMember[]);
  const [circleOptions, setCircleOptions] = useState([] as SelectOption[]);
  const [accountOptions, setAccountOptions] = useState([] as SelectOption[]);
  const [accountOption, setAccountOption] = useState(undefined as SelectOption | undefined);
  const [circleOption, setCircleOption] = useState(undefined as SelectOption | undefined);
  
  
  function onChabgeMemberValue(name: string, value: string) {
    setFormParam(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  async function deleteMember(accountId: string) {
    if(isDisabled) { return; }
    setIsDisabled(true);
    var param = { accountId: accountId };
    const response = await postAsync<string>('CircleMember/Remove', param);
    setIsDisabled(false);
    if (response.code === 1) {
      toast.success("削除しました");
      setFormParam({} as AddCircleMemberParam);
      setDialogVisible(false);
      setVersion(version + 1);
    } else {
      toast.error(response.message ?? '');
    }
  }


  async function register() {
    if(isDisabled) { return; }
    setIsDisabled(true);
    var param = {
      accountId: accountOption?.value,
      circleId: circleOption?.value
    }
    const response = await postAsync<string>('CircleMember/Add', param);
    setIsDisabled(false);
    if (response.code === 1) {
      toast.success("登録しました");
      setFormParam({} as AddCircleMemberParam);
      setDialogVisible(false);
      setVersion(version + 1);
    } else {
      toast.error(response.message ?? '');
    }
  }

  useEffect(() => {
    const fetchList = async () => {
      const list = await fetchAsync<CircleMember[]>('CircleMember/GetList', null);
      setCircleMemberList(list);
    };

    const fetchCircles = async () => {
      const list = await fetchAsync<Circle[]>('Circle/GetList', null);
      var options = [] as SelectOption[];
      for (var item of list) {
        options.push({ label: item.name, value: item.circleId } as SelectOption);
      }
      setCircleOptions(options);
    };
  
    const fetchAccounts = async () => {
      const list = await fetchAsync<Account[]>('Account/GetList', null);
      var options = [] as SelectOption[];
      for (var item of list) {
        options.push({ label: item.name, value: item.accountId! } as SelectOption);
      }
      setAccountOptions(options);
    };
  
    fetchList();
    fetchCircles();
    fetchAccounts();
  }, [version]);

  return <>
    { dialogVisible && 
    <div className="bg-white pt-4 second-bg-color px-6 rounded-md second-bg-color input-dialog" style={{ height: '600px' }}>
      <div className="mb-4 text-gray-600 text-sm inline-flex w-full justify-between">
        <span>メンバー追加</span>
        <img onClick={(e) => setDialogVisible(false)} style={{ width: '20px' }} className="hover-button" src="/images/icon-close.svg"></img>
      </div>
      <div className="mb-2">
        <Select value={circleOption} onChange={(e) => setCircleOption(e!)} options={circleOptions} placeholder="サークル"></Select>
      </div>
      <div className="mb-6">
      <Select value={accountOption} onChange={(e) => setAccountOption(e!)} options={accountOptions} placeholder="アカウント"></Select>
      </div>
      <div className="mb-4">
        <button disabled={isDisabled} onClick={register} className="simple-button hover-button">追加</button>
      </div>
    </div>
    }

    <Toaster></Toaster>
    <MemberHeader></MemberHeader>
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <div className="pl-8 pt-4">
      <div className="inline-flex text-gray-700 items-end">
        <button className="simple-button" onClick={(e) => setDialogVisible(true)}>メンバー追加</button>
      </div>
      <table className="data-table mt-2">
        <thead>
          <tr>
            <th>サークル</th>
            <th>アカウント</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          { circleMemberList.map((e) => (
            <tr>
              <td>{e.circleName}</td>
              <td>{e.accountName}</td>
              <td><button className="border rounded text-sm px-4" onClick={(eve) => deleteMember(e.accountId!)}>削除</button></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </>
}
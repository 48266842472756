import { FC, ReactElement, useState } from "react";
import { Header } from "../parts/Header";

export const GalleryPage: FC = () => {
  const [videoUrl, setVideoUrl] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  function showVideoArea(url: string) {
    setVideoUrl(url);
  }

  function closeDialog() {
    setVideoUrl('');
  }

  return <div>
    <Header></Header>
    { videoUrl !== '' && 
    <div id="videoArea" className="bg-black px-1 py-1 rounded text-center fixed" style={{ zIndex: '1', width: 'fit-content', margin: 'auto', left: '0', right: '0', top: '140px', backgroundColor: '#fff' }}>
      <div style={{ textAlign: 'right' }}>
        <span className="video-close-buton" onClick={closeDialog}>
          <img src="/images/icon-close.png" className="h-[32px] w-[32px] absolute" style={{ marginTop: '-4px', right: '16px', backgroundColor: 'white', borderRadius: '16px' }}></img>
        </span>
      </div>
      <div>
        <video controls style={{ width: '600px' }}>
          <source src={videoUrl}></source>
        </video>
      </div>
    </div>
    }

    <div className="w-[920px] margin-auto mt-8" style={{ maxWidth: '100%' }}>
      <div className="inline-flex">
        <div className="relative">
          <img className="image-block" src="/images/IMG_5016.jpg" onClick={(e) => showVideoArea('/images/IMG_5016.mp4')}></img>
          <img className="absolute w-[24px] h-[24px]" style={{ right: '8px', top: '8px' }} src="/images/icon-play.png"></img>
        </div>
        <img className="image-block" src="/images/IMG_3011edited.jpg"></img>
        <div className="relative">
          <img className="image-block" src="/images/IMG_4995.jpg" onClick={(e) => showVideoArea('/images/IMG_4995.mp4')}></img>
          <img className="absolute w-[24px] h-[24px]" style={{ right: '8px', top: '8px' }} src="/images/icon-play.png"></img>
        </div>
      </div>
      <div className="inline-flex">
        <img className="image-block" src="/images/IMG_4577-scaled.jpg"></img>
        <div className="relative">
          <img className="image-block" src="/images/IMG_4508.jpg" onClick={(e) => showVideoArea('/images/IMG_4508.mp4')}></img>
          <img className="absolute w-[24px] h-[24px]" style={{ right: '8px', top: '8px' }} src="/images/icon-play.png"></img>
        </div>
        <img className="image-block" src="/images/IMG_3334.jpg"></img>
      </div>
      <div className="inline-flex">
        <div className="relative">
          <img className="image-block" src="/images/IMG_4016-e1580220802693.jpg" onClick={(e) => showVideoArea('/images/IMG_4016.mp4')}></img>
          <img className="absolute w-[24px] h-[24px]" style={{ right: '8px', top: '8px' }} src="/images/icon-play.png"></img>
        </div>
        <div className="relative">
          <img className="image-block" src="/images/IMG_4006.jpg" onClick={(e) => showVideoArea('/images/IMG_4006.mp4')}></img>
          <img className="absolute w-[24px] h-[24px]" style={{ right: '8px', top: '8px' }} src="/images/icon-play.png"></img>
        </div>
        <img className="image-block" src="/images/IMG_3312_edited7.jpg"></img>
      </div>
      
    </div>


  </div>
}
export class SelectOption {
  constructor(label: string | null, value: string | null, suffix: string | undefined = '') {
    this.label = `${label}${suffix}` ?? '';
    this.value = value ?? '';
  }
  label: string = "";
  value: string = "";
  public static Empty() : SelectOption {
    return new SelectOption('','');
  }
}

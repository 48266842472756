import { SelectOption } from "./SelectOption";

export class VisitorRequest {
  uid: number | undefined;
  status: string | undefined;
  email: string  = '';
  name: string = '';
  gender: string = '';
  genderOption: SelectOption | undefined;
  age: string = '';
  ageOption: SelectOption | undefined;
  station: string = '';
  badmintonHistory: string = '';
  badmintonHistoryOption: SelectOption | undefined;
  badmintonLevel: string = '';
  badmintonLevelOption: SelectOption | undefined;
  firstDate : string = '';
  firstDateOption: SelectOption | undefined;
  selfIntroduction: string = '';
  agree: string = '';
  isSentFirstMail: number | undefined;
  isSentSecondMail: number | undefined;
  visitorId: string | undefined;
}
import { FC, useState, useEffect } from "react";
import { postAsync } from "../../ApiHandler";
import { fetchAsync } from "../../ApiHandler";
import { BoardItem, BoardResponse } from "../../Model/BoardItem";
import { SelectOption } from "../../Model/SelectOption";
import { Participation } from "../../Model/Participation";
import { TrainingDay } from "../../Model/TrainingDay";
import { Account } from "../../Model/Account";
import { ToDateString } from "../../utils/DateUtil";
import { TrimSeconds } from "../../utils/DateUtil";
import { getDayOfWeekString } from "../../utils/DateUtil";
import { getAccountId } from "../../CookieHandler";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import { MemberHeader } from "../../parts/MemberHeader";
import { Helmet } from "react-helmet";

export const ScheduleBoardPage: FC = () => {
  const attendanceOptions = [
    { label: '未回答', value: 'empty' },
    { label: '● 参加', value: 'attend' },
    { label: '✗ 不参加', value: 'absence' },
    { label: '□ 途中参加', value: 'middle' },
    { label: '△ 早退', value: 'early' },
  ] as SelectOption[];

  const [notification, setNotification] = useState("");
  const [boardItems, setBoardItems] = useState(undefined as BoardResponse | undefined);
  const [myParticipateOptions, setMyParticipateOptions] = useState({} as { [key: string]: SelectOption });
  const [version, setVersion] = useState(0);
  const myAccountId = getAccountId();
  const [myAccount, setMyAccount] = useState(undefined as Account | undefined);

  useEffect(() => {
    const accountId = getAccountId();
    const fetchList = async () => {
      const res = await fetchAsync<BoardResponse>('TrainingDay/GetListForBoard', null);

      if (version === 0) {
        var newList = {} as { [key: string]: SelectOption };
        for(var item of res.items!) {
          var myAnswers = item.participations!.filter(e => e.accountId === accountId && e.date === item.trainingDay?.date);
          if (myAnswers.length >= 1) {
            const myAnswer = myAnswers[0];
            var option = attendanceOptions.filter((e) => e.value === myAnswer.status)[0];
            newList[item.trainingDay?.date!] = option;
          } else {
            var option = attendanceOptions.filter((e) => e.value === 'empty')[0];
            newList[item.trainingDay?.date!] = option;
          }
        }
        setMyParticipateOptions(newList);
        setBoardItems(res);
      } else {
        setBoardItems(res);
      }
    };

    const fetchNotification = async () => {
      const notification = await fetchAsync<string>('KeyValue/GetNotificationText', null);
      setNotification(notification);
    };


    const fetchMyAccount = async () => {
      const account = await fetchAsync<Account>('AdminAccount/GetMyAccount', null);
      setMyAccount(account);
    };
  
    fetchMyAccount();
    fetchList();
    fetchNotification();
  }, [version]);

  async function onChangeStatus(date: string, newStatus: string) {
    const newMap = {...myParticipateOptions};
    var option = attendanceOptions.filter((e) => e.value === newStatus)[0];
    newMap[date] = option;
    setMyParticipateOptions(newMap);
    await sendChangeStatus(date, newStatus);
    setVersion(version+1);
  }

  async function sendChangeStatus(date: string, status: string) {
    var param = { date: date, status: status };
    const response = await postAsync<string>('Participation/UpsertParticipation', param);
    if (response.code === 1) {
      toast.success("更新しました");
    } else {
      toast.error(response.message ?? '');
    }
  }

  function getStatus(account: Account, day: TrainingDay, participations: Participation[]) : string {
    const partList = participations.filter(e => e.accountId === account.accountId);
    if (partList.length === 0) return '';

    const part = partList[0];
    if(part.status === 'attend') { return '●' };
    if(part.status === 'absence') { return '✗' };
    if(part.status === 'middle') { return '□' };
    if(part.status === 'early') { return '△' };
    return '';
  }


  function isReceiptionAvailable(account: Account, day: TrainingDay, participations: Participation[]) : boolean {
    const partList = participations.filter(e => e.accountId === account.accountId);
    if (partList.length === 0) return false;

    const part = partList[0];
    if(part.status === 'attend') { return true };
    if(part.status === 'early') { return true };
    return false;
  }


  return <>
    <Toaster></Toaster>
    <MemberHeader></MemberHeader>
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <div className="ml-2 sm:ml-8 mt-2">
        { notification !== '' &&
          <div className="px-2 py-2 mr-4 mb-3" style={{ whiteSpace: 'pre-line', fontSize: '13px', border: '1px solid rgb(33, 150, 243)', borderRadius: '4px', color: 'rgb(33, 150, 243)' }}>
            <span>{ notification }</span>
          </div>
        }
      <div id="boardArea" className="inline-flex pb-4" style={{ maxWidth: '100vw', overflow: 'scroll', minHeight: '100vh' }}>
        { boardItems?.items?.length === 0 &&
          <div>登録されている練習日はありません</div>
        }
        <table className="board-table block">
          <thead>
            <tr style={{ border: 'none' }}>
              <th></th>
              { boardItems?.items!.map((e) => (
                <th className="text-xs text-left pb-1" style={{ fontWeight: '400', width: '140px', verticalAlign: 'baseline' }}>
                  <div><a style={{ color: '#1a73e8' }} href={e.trainingPlace?.googleMapUrl}>{e.trainingPlace?.placeName}</a> ({e.trainingPlace?.courtCount}面)</div>
                  <div>{ToDateString(e.trainingDay?.date!)} ({getDayOfWeekString(e.trainingDay?.date!)})</div>
                  <div>{TrimSeconds(e.trainingDay?.startTime!)} 〜 {TrimSeconds(e.trainingDay?.endTime!)}</div>
                  { e.trainingDay?.isOtherClub === true &&
                    <>
                      <div>上限人数: {e.trainingDay.maxMemberCount}人</div>
                      <div><a style={{ color: '#1a73e8' }} href={e.trainingDay.clubUrl} target="_blank">{ e.trainingDay.clubName }</a>の練習に参加</div>
                    </>
                  }
                  { e.circle?.circleId == myAccount?.circleId &&
                    <div className="text-sm mt-1 text-red-400">サークルメンバー</div>
                  }
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
          { boardItems && boardItems!.items!.length >= 1 && 
            <tr>
              <td className="mr-2 px-2 py-4" style={{ textAlign: 'start' }}>{ boardItems?.myName }</td>
              { boardItems?.items!.map((item) => (
                <td className="" style={{ textAlign: 'start', minWidth: '140px' }}>
                  { item.trainingDay?.isClosed !== 1 &&
                  <Select isSearchable={false} onChange={(e) => onChangeStatus(item.trainingDay?.date!, e?.value!)} value={myParticipateOptions[item.trainingDay?.date!]} options={attendanceOptions}></Select>
                  }
                  { item.trainingDay?.isClosed === 1 &&
                    <span>{ myParticipateOptions[item.trainingDay?.date!].label }</span>
                  }
                </td>
              ))}
            </tr>
          }
          { boardItems?.accountList!.map((acc) => (
            <>
            { acc.accountId === myAccountId && 
              <></> 
            }
            { acc.accountId !== myAccountId && 
              <tr>
                <td style={{ textAlign: 'start' }}>{acc.name}</td>
                { boardItems?.items!.map((item) => (
                  <td style={{ backgroundColor : acc.circleId === item.circle?.circleId && isReceiptionAvailable(acc!, item.trainingDay!, item.participations!) ? '#fffc008f' : '' }}>{ getStatus(acc!, item.trainingDay!, item.participations!)}</td>
                ))}
              </tr>
            }
            </>
          ))}
          { boardItems !== undefined && boardItems?.items!.length >= 1 &&
          <>
          <tr>
            <td className="h-[32px]"></td>
            { boardItems?.items!.map((item) => (
              <td className="h-[32px]"></td>
            ))}
          </tr>
          <tr>
            <td style={{ textAlign: 'start' }}>ビジター</td>
            { boardItems?.items!.map((item) => (
              <td>{item.visitorCount}</td>
            ))}
          </tr>
          <tr>
            <td style={{ textAlign: 'start' }}>● 参加</td>
            { boardItems?.items!.map((item) => (
              <td>{item.attendCount}</td>
            ))}
          </tr>
          <tr>
            <td style={{ textAlign: 'start' }}>□ 途中参加</td>
            { boardItems?.items!.map((item) => (
              <td>{item.middleCount}</td>
            ))}
          </tr>
          <tr>
            <td style={{ textAlign: 'start' }}>△ 早退</td>
            { boardItems?.items!.map((item) => (
              <td>{item.earlyCount}</td>
            ))}
          </tr>
          <tr>
            <td style={{ textAlign: 'start' }}>参加合計</td>
            { boardItems?.items!.map((item) => (
              <td>{item.visitorCount! + item.attendCount! + item.middleCount! + item.earlyCount!}</td>
            ))}
          </tr>
          <tr>
            <td style={{ textAlign: 'start' }}>✗ 不参加</td>
            { boardItems?.items!.map((item) => (
              <td>{item.absenceCount}</td>
            ))}
          </tr>
          </>
          }
          </tbody>
        </table>
        { boardItems === undefined &&
            <div className="text-sm text-gray-600">Loading....</div>
        }
      </div>
    </div>
  </>
}
import { FC, useState, useEffect } from "react";
import { Header } from "../parts/Header";
import { SelectOption } from "../Model/SelectOption";
import Select from "react-select";
import { postWithoutSign, fetchWithoutSignAsync, postAsync } from "../ApiHandler";
import { BoardItem } from "../Model/BoardItem";
import { ToDateString } from "../utils/DateUtil";
import toast, { Toaster } from "react-hot-toast";
import { VisitorRequest } from "../Model/VisitoreRequest";

export const ContactPage: FC = () => {
const [dateOptions, setDateOptions] = useState([] as SelectOption[]);
const [isDisabled, setIsDisabled] = useState(false);
const [isSent, setIsSent] = useState(false);
const [req, setVisitorRequest] = useState(new VisitorRequest());
const [isClosed, setIsClosed] = useState(false);

useEffect(() => {
  const fetchList = async () => {
    const list = await fetchWithoutSignAsync<BoardItem[]>('TrainingDay/GetListForVisitor', null);
    const options = [] as SelectOption[];
    for (var item of list) {
      const dateString = ToDateString(item.trainingDay!.date!);
      const hourString = `${item.trainingDay?.startTime} - ${item.trainingDay?.endTime}`
      const labelText = `${dateString} (${hourString}) ${item.trainingPlace!.areaName} `;
      const option = { label: labelText, value: item.trainingDay?.date } as SelectOption;
      options.push(option);
    }
    setDateOptions(options);
  };

  fetchList();
}, []);

const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: string) => {
  setVisitorRequest(prevState => ({
    ...prevState,
    [name]: event.target.value
  }));
};

const handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
  setVisitorRequest(prevState => ({
    ...prevState,
    [name]: event.target.checked.toString()
  }));
};

const handleSelectChange = (event: SelectOption | null, name: string) => {
  setVisitorRequest(prevState => ({
    ...prevState,
    [name]: new SelectOption(event!.label, event!.value)
  }));
}

async function send() {
  if(isDisabled) {
    return;
  }
  setIsDisabled(true);
  if(req.agree !== 'true') {
    setIsDisabled(false);
    toast.error('サークルの運営方針に同意する必要があります');
    return;
  };
  req.age = req.ageOption?.value ?? '';
  req.badmintonHistory = req.badmintonHistoryOption?.value ?? '';
  req.badmintonLevel = req.badmintonLevelOption?.value ?? '';
  req.firstDate = req.firstDateOption?.value ?? '';
  req.gender = req.genderOption?.value ?? '';
  const response = await postWithoutSign('VisitorRequest/AddVisitorRequest', req);
  setIsDisabled(false);
  if(response.code === 1) {
    setIsSent(true);
  } else {
    toast.error(response.message!);
  }
}

  const genderOptions = [
    { label: '男性', value: 'man' },
    { label: '女性', value: 'woman' },
  ] as SelectOption[];

  const ageIptions = [,
    { label: '10代(高校生から)', value: '15' },
    { label: '20代前半', value: '20' },
    { label: '20代後半', value: '25' },
    { label: '30代前半', value: '30' },
    { label: '30代後半', value: '35' },
    { label: '40代前半', value: '40' },
    { label: '40代後半', value: '44' },
    { label: '50代前半', value: '50' },
    { label: '50代後半', value: '55' },
    { label: '60代〜', value: '60' },
  ] as SelectOption[];

  const badomintonHistoryOptions = [
    // { label: 'なし（募集停止中）', value: 'none' },
    // { label: '2年未満 (募集停止中)', value: 'under2' },
    // { label: '2年以上 ~ 6年未満 (募集停止中', value: 'under5' },
    { label: '6年以上 ~ 10年未満', value: 'under10' },
    { label: '10年以上', value: 'over10' },
  ] as SelectOption[];

  return <div>
    <Toaster></Toaster>
    <Header></Header>
    
    <div>
      <img src="/images/top-03.jpg" style={{ width: '100%' }}></img>
    </div>
    <div  className="mt-8 margin-auto px-6" style={{ width: '400px', maxWidth: '100%' }}>
    { !isSent && !isClosed &&
      <>
      <div className="mt-16 mb-8">
      <div className="sub-title">初参加希望の申し込みについて</div>
        <a href="/join" className="underline text-lg">参加方法・注意事項</a>のページをご確認の上、以下のフォームからお申し込みください。
      </div>
      <div className="text-gray-500">メールアドレス</div>
      <div className="mb-4">
        <input type="text" value={req.email} onChange={(e) => handleChange(e, 'email')} className="border px-2 py-1 rounded w-full"></input>
      </div>
      <div className="text-gray-500">氏名</div>
      <div className="mb-4">
        <input value={req.name} onChange={(e) => handleChange(e, 'name')} type="text" className="border px-2 py-1 rounded w-full"></input>
      </div>
      <div className="text-gray-500 mb-1">性別</div>
      <div className="inline-flex items-center mb-4">
      <div className="mb-4">
        <Select value={req.genderOption} onChange={(e) => handleSelectChange(e, 'genderOption')} options={genderOptions} placeholder="選択してください"></Select>
      </div>
      </div>
      <div className="text-gray-500">年齢</div>
      <div className="mb-4">
        <Select value={req.ageOption} onChange={(e) => handleSelectChange(e, 'ageOption')} options={ageIptions} placeholder="選択してください"></Select>
      </div>
      <div className="text-gray-500">お住まいの最寄り駅</div>
      <div className="mb-4">
        <input value={req.station} onChange={(e) => handleChange(e, 'station')} type="text" className="border px-2 py-1 rounded w-full" placeholder="〇〇線 〇〇駅"></input>
      </div>      
      <div className="text-gray-500">バドミントン歴</div>
      <div className="mb-4">
        <Select value={req.badmintonHistoryOption} onChange={(e) => handleSelectChange(e, 'badmintonHistoryOption')} options={badomintonHistoryOptions} placeholder="選択してください"></Select>
      </div>
      <div className="text-gray-500">初参加希望日</div>
      <div className="mb-4">
        <Select  value={req.firstDateOption} onChange={(e) => handleSelectChange(e, 'firstDateOption')} options={dateOptions} placeholder="選択してください"></Select>
      </div>
      <div className="text-gray-500">簡単な自己紹介</div>
      <div className="mb-4">
        <textarea value={req.selfIntroduction} onChange={(e) => handleChange(e, 'selfIntroduction')} className="border px-2 py-1 rounded w-full h-[100px]"></textarea>
      </div>

      <div className="inline-flex items-center mb-8">
        <input  value={req.agree} onChange={(e) => handleCheckedChange(e, 'agree')} id="tosAgree" type="checkbox" className="h-[20px] w-[20px] mr-2"></input>
        <label htmlFor="tosAgree">注意事項を読んでいて、サークルの運営方針に同意する</label>
      </div>

      <div className="mb-24">
        <button disabled={isDisabled} onClick={send} className="w-full border rounded py-2 simple-button">送信</button>
      </div>
    </>
    }
    </div>
    { isSent &&
      <div className="border rounded px-4 py-2 margin-auto" style={{ maxWidth: '360px' }}>
        初参加希望を受け付けました。メールの返信をお待ちください。
      </div>
    }
    { isClosed &&
      <div className="border rounded px-4 py-2 margin-auto" style={{ maxWidth: '360px' }}>
        現在、新規参加募集を停止しております。
      </div>
    }

  </div>
}
import { FC } from "react";
import { Header } from "../parts/Header";

export const JoinPage: FC = () => {
  return <div>
    <Header></Header>
    <div>
      <img src="/images/top-04.jpg" style={{ width: '100%' }}></img>
    </div>

    <div className="margin-auto px-4 box-border" style={{ width: '720px', maxWidth: '100%' }}>

      <div className="mt-12 mb-12">
        <div className="sub-title">参加方法</div>
        <div>1. このページを読み、禁止事項や募集対象について確認します。</div>
        <div>2. 直近の練習日程の中から参加したい日を決めて、初参加希望の申し込みの入力フォームから必要事項を送信してください。</div>
        <div>3. 入力したメールアドレス宛に、練習場所や集合時間など、当日の練習について詳細を連絡します。</div>
      </div>

      <div className="mt-12 mb-12">
        <div className="sub-title">申し込みについて</div>
        <div>継続して参加できるか不安。。。という人は <a href="/gallery" className="underline">こちらのページ</a> の写真を見てください。サークルの雰囲気やバドミントンのレベルが分かると思います。</div>
        <div>練習日によっては、参加人数が多くて初参加を締め切っていたり、人数が足りず中止になることがあります。</div>
        <div className="mt-2">なお、</div>
        <div>・定期的に継続して練習に参加できない方</div>
        <div>・お住いの場所、年齢、バドミントン歴やレベル、募集対象外・停止中に該当する方</div>
        <div>・初参加をドタキャンする方</div>
        <div>・申し込んだ内容的に、当クラブに合わないと思われる方</div>
        <div>・ニックネームや偽名で申し込む方、連絡先を知られたくないなど、身元不明な方</div>
        <div className="mt-2">には、返信していません。</div>
      </div>

      <div className="mt-12 mb-12">
        <div className="sub-title">募集対象</div>
        <div className="mb-4">
          初参加だけでなく、２回以上／月など、定期的に継続して練習に参加できる人を募集しています。
          そのため、東京都世田谷区にお住まい、または世田谷区近隣の市・区にお住まいの方を募集対象としています。
        </div>
        <div className="mb-4">
          なお、バドミントンの基本・基礎（ルール、基礎打ちなど）を教える環境が無いため、未経験者と初心者は募集停止中です。
        </div>
        <div style={{ color: 'red', fontWeight: 700 }}>
          現在バドミントン歴 6年以上の方のみを募集しております。ご注意ください。
        </div>
      </div>

      <div className="mt-12 mb-12">
        <div className="sub-title">禁止事項</div>
        <div>・無断での飛び入り参加、連絡無しのドタキャン</div>
        <div>・出会い系目的、他サークル等への引き抜きを目的とした参加</div>
        <div>・サークル内で個人的な活動を繰り返す方、試合のペアや相手を選ぶ方。<br/>（特定のメンバーのみで試合する方）</div>
        <div>・ネットワークビジネス（アムウェイ等）、マルチ商法、セミナー、講習等の案内や勧誘</div>
        <div>・宗教、政治関係の案内や勧誘</div>
        <div>・サークル内でお金の貸し借りを繰り返す方</div>
      </div>

      <div className="mt-12 mb-8">
        <div className="sub-title">知人連れの参加</div>
        <div className="font-bold">初参加の場合</div>
        <div className="mb-4">
          ほとんどの方は１人で初参加していますが、ご友人と一緒に参加していただくことも可能です。<br/>
          ご友人と一緒に参加されたい場合には、それぞれ初参加の申込みをしてください。
        </div>
        <div className="font-bold">いつも参加しているメンバーの場合</div>
        <div>知人連れで参加したい（知人をビジターとして参加させたい）ときは、代表まで事前に連絡ください。</div>
      </div>

      <div className="mt-2 mb-24">
        <a className="underline" href="/contact">初参加の申し込みはこちらから</a>
      </div>

    </div>
  </div>
}
import { FC, useState, useEffect } from "react";
import { fetchAsync } from "../../ApiHandler";
import { Helmet } from "react-helmet";
import { GymDate } from "../../Model/GymDate";
import { MemberHeader } from "../../parts/MemberHeader";
import { getDayOfWeekString, ToDateString, ToMinutesString } from "../../utils/DateUtil";

export const GymDatePage: FC = () => {
  const [gymDateList, setgGymDateList] = useState<GymDate[]>([]);
  
  const [isHolidayOnly, setIsHolidayOnly] = useState(true);
  const [isAvailableOnly, setIsAvailableOnly] = useState(true);
  const [gymName, setGymName] = useState("");
  
  useEffect(() => {
    fetchList();
  }, []);

  async function fetchList(){
    var param = {
      isHolidayOnly: isHolidayOnly,
      isAvailableOnly: isAvailableOnly,
      gymName: gymName
    };

    const list = await fetchAsync<GymDate[]>('GymDate/GetList', param);
    setgGymDateList(list);
  }

  return <>
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <MemberHeader></MemberHeader>
    <div className="pl-8 pt-4">
      <div className="inline-flex text-gray-700 items-center mb-4">
        <input type="checkbox" id="holiday" checked={isHolidayOnly} onChange={(e) => setIsHolidayOnly(e.target.checked)} className="h-[16px] w-[16px] mr-2"></input>
        <label htmlFor="holiday" className="mr-2 text-sm">土日のみ</label>
        <input type="checkbox" id="available" checked={isAvailableOnly} onChange={(e) => setIsAvailableOnly(e.target.checked)} className="h-[16px] w-[16px] mr-2"></input>
        <label htmlFor="available" className="mr-2 text-sm">空きのみ</label>
        <input className="border ml-2 mr-6 px-2 rounded" placeholder="施設名" value={gymName} onChange={(e) => setGymName(e.target.value)}></input>
        <button className="simple-button" onClick={(e) => fetchList()}>検索</button>
      </div>
      <table className="data-table mt-4 mb-8">
        <thead>
          <tr>
            <th>施設名</th>
            <th>施設詳細</th>
            <th>空き状況</th>
            <th>日付</th>
            <th>データ取得日時</th>
          </tr>
        </thead>
        <tbody>
          { gymDateList.map((e) => (
            <tr>
              <td>{e.placeName}</td>
              <td>{e.placeDetailName}</td>
              <td>{e.status}</td>
              <td>{ToDateString(e.date!)} ({getDayOfWeekString(e.date!)})</td>
              <td>{ToMinutesString(e.updatedDate!)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </>
}
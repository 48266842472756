import { FC, useState, useEffect } from "react";
import { postAsync } from "../../ApiHandler";
import { fetchAsync } from "../../ApiHandler";
import { BoardItem } from "../../Model/BoardItem";
import { SelectOption } from "../../Model/SelectOption";
import toast, { Toaster } from "react-hot-toast";
import { Account } from "../../Model/Account";
import { MemberHeader } from "../../parts/MemberHeader";
import { ToMinutesString } from "../../utils/DateUtil";
import { VisitorRequest } from "../../Model/VisitoreRequest";
import { ToDateString } from "../../utils/DateUtil";
import { Helmet } from "react-helmet";
import { BoardResponse } from "../../Model/BoardItem";
import Select from "react-select";

class AddMemberParam {
  email: string = '';
  name: string = '';
}

export const VisitorRequestListPage: FC = () => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [version, setVersion] = useState(0);
  const [visitorList, setVisitorList] = useState([] as VisitorRequest[]);
  const [board, setBoard] = useState(undefined as BoardResponse | undefined);
  const [dateOptions, setDateOptions] = useState([] as SelectOption[]);
  const [selectedDate, setSelectedDate] = useState(undefined as SelectOption | undefined);
  const [selectedVisitor, setSelectedVisitor] = useState('');
  const [visitorName, setVisitorName] = useState('');
  
  async function register() {
    if(isDisabled) { return; }
    setIsDisabled(true);
    const response = await postAsync<VisitorRequest[]>('AdminAccount/AddAccount', null);
    setIsDisabled(false);
    if (response.code === 1) {
      toast.success("登録しました");
      setDialogVisible(false);
      setVersion(version + 1);
    } else {
      toast.error(response.message ?? '');
    }
  }

  useEffect(() => {
    const fetchList = async () => {
      const response = await fetchAsync<BoardResponse>('TrainingDay/GetListForBoard', null);
      setBoard(response);

      var dayOptions = [];
      for(var item of response.items!) {
        var option = { label: ToDateString(item.trainingDay?.date!), value: item.trainingDay?.date} as SelectOption;
        dayOptions.push(option);
      }
      setDateOptions(dayOptions);
    };

    fetchList();
  }, [version]);


  async function sendFirstMail(visitorId: string, name: string) {
    if(isDisabled) { return; }
    if(!window.confirm(`${name}に初回案内メールを送ります。よろしいですか？`)) { return; }
    setIsDisabled(true);
    const param = { visitorId: visitorId };
    const response = await postAsync<VisitorRequest[]>('VisitorRequest/SendFirstInvitation', param);
    setIsDisabled(false);
    if (response.code === 1) {
      toast.success("初回案内メールを送りました");
      setDialogVisible(false);
      setVersion(version + 1);
    } else {
      toast.error(response.message ?? '');
    }
  }
  
  async function addVisitorParticipation() {
    if(isDisabled) { return; }
    setIsDisabled(true);
    const param = { visitorId: selectedVisitor, date: selectedDate?.value };
    const response = await postAsync<string>('VisitorRequest/CreateVisitorParticipation', param);
    setIsDisabled(false);
    if (response.code === 1) {
      toast.success("設定しました");
      setDialogVisible(false);
      setVersion(version + 1);
    } else {
      toast.error(response.message ?? '');
    }
  }

  async function ignore(visitorId: string, name: string) {
    if(isDisabled) { return; }
    if(!window.confirm(`${name}をスルーします。よろしいですか`)) { return; }
    setIsDisabled(true);
    const param = { visitorId: visitorId };
    const response = await postAsync<VisitorRequest[]>('VisitorRequest/Ignore', param);
    setIsDisabled(false);
    if (response.code === 1) {
      toast.success("スルーしました");
      setDialogVisible(false);
      setVersion(version + 1);
    } else {
      toast.error(response.message ?? '');
    }
  }

  function showDialog(visitorId: string, visitorName: string) {
    setSelectedVisitor(visitorId);
    setVisitorName(visitorName);
    setDialogVisible(true);
  }

  useEffect(() => {
    const fetchList = async () => {
      const list = await fetchAsync<VisitorRequest[]>('VisitorRequest/GetList', null);
      setVisitorList(list);
    };
  
    fetchList();
  }, [version]);

  return <>
    <Toaster></Toaster>
    <MemberHeader></MemberHeader>
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>

    { dialogVisible && 
    <div className="bg-white pt-4 second-bg-color px-6 rounded-md second-bg-color input-dialog" style={{ height: '400px' }}>
      <div className="mb-4 text-gray-600 text-sm inline-flex w-full justify-between">
        <span>ビジター参加追加</span>
        <img onClick={(e) => setDialogVisible(false)} style={{ width: '20px' }} className="hover-button" src="/images/icon-close.svg"></img>
      </div>
      <div className="mb-2">
        <span>{visitorName} ({selectedVisitor})</span>
      </div>
      <div className="mb-6">
        <Select value={selectedDate} onChange={(e) => setSelectedDate(e!)} options={dateOptions} placeholder="練習日"></Select>
      </div>
      <div className="mb-4">
        <button disabled={isDisabled} onClick={addVisitorParticipation} className="simple-button hover-button">追加</button>
      </div>
    </div>
    }

    <div className="pl-8 pt-4">
      <table className="data-table mt-2">
        <thead>
          <tr>
            <td style={{ width: '160px' }}></td>
            <th>ID</th>
            <th>ステータス</th>
            <th>初回案内</th>
            <th>メールアドレス</th>
            <th>氏名</th>
            <th>性別</th>
            <th>年齢</th>
            <th>最寄り駅</th>
            <th>バドミントン歴</th>
            <th>バドミントンレベル</th>
            <th>初参加希望日</th>
          </tr>
        </thead>
        <tbody>
          { visitorList.map((e) => (
            <>
            <tr>
              <td>
                { e.status === 'created' && e.isSentFirstMail === 0 && 
                  <>
                  <button className="border rounded px-2 mr-2" onClick={(elem) => sendFirstMail(e.visitorId!, e.name)}>初回案内</button>
                  <button className="border rounded px-2" onClick={(elem) => ignore(e.visitorId!, e.name)}>スルー</button>
                  </>
                }
                { e.status === 'created' && e.isSentFirstMail === 1 &&
                  <button className="border rounded px-2" onClick={(elem) => showDialog(e.visitorId!, e.name!) }>練習参加</button>
                }
              </td>
              <td>{e.uid}</td>
              <td>{e.status}</td>
              <td>{e.isSentFirstMail}</td>
              <td>{e.email}</td>
              <td>{e.name}</td>
              <td>{e.gender}</td>
              <td>{e.age}</td>
              <td>{e.station}</td>
              <td>{e.badmintonHistory}</td>
              <td>{e.badmintonLevel}</td>
              <td>{ToDateString(e.firstDate)}</td>
            </tr>
            <tr>
              <td></td>
              <td colSpan={12}>自己紹介: {e.selfIntroduction}</td>
            </tr>
            <tr>
              <td className="h-[24px]" colSpan={13}></td>
            </tr>
            </>
          ))}
        </tbody>
      </table>
    </div>
  </>
}
import axios from 'axios';
import { getToken } from './CookieHandler';
import { getIsLoggedIn } from './CookieHandler';
import { redirect } from 'react-router-dom';

const loginPage = '/member/sign-in';

export async function postWithoutSign<T>(url:string, param: any): Promise<ApiResponse<T>> {
  const fullPath = `${process.env.REACT_APP_API_URL}/${url}`;
  const response = await axios.post(fullPath, param);
  console.log(response.data);
  return response.data as ApiResponse<T>;
}

export async function postAsync<T>(url:string, param: any): Promise<ApiResponse<T>> {
  if (!getIsLoggedIn()) { redirect(loginPage); }
  const fullPath = `${process.env.REACT_APP_API_URL}/${url}`;
  let authHeader = { Authorization: `Bearer ${getToken()}` };
  const response = await axios.post(fullPath, param, { headers: authHeader });
  console.log(response.data);
  return response.data as ApiResponse<T>;
}

export async function fetchAsync<T>(url:string, params: any | undefined): Promise<T> {
  if (!getIsLoggedIn()) { redirect(loginPage); }
  const fullPath = `${process.env.REACT_APP_API_URL}/${url}`;
  let authHeader = { Authorization: `Bearer ${getToken()}` };
  const response = await axios.get(fullPath, { params: params, headers: authHeader });
  console.log(response.data);
  return response.data as T;
}

export async function fetchWithoutSignAsync<T>(url:string, params: any | undefined): Promise<T> {
  let authHeader = { Authorization: `Bearer ${getToken()}` };
  const fullPath = `${process.env.REACT_APP_API_URL}/${url}`;
  const response = await axios.get(fullPath, { params: params, headers: authHeader });
  console.log(response.data);
  return response.data as T;
}

export class ApiResponse<T> {
    code: number | undefined;
    message: string | undefined;
    data: T | undefined;
    isOk() : boolean { return this.code === 1; }
}

import { FC, useState, useEffect } from "react";
import { fetchAsync } from "../../ApiHandler";
import toast, { Toaster } from "react-hot-toast";
import { MemberHeader } from "../../parts/MemberHeader";
import { ToDateString } from "../../utils/DateUtil";
import { Helmet } from "react-helmet";
import { VisitorParticipation } from "../../Model/VisitorParticipation";

export const VisitorParticipationListPage: FC = () => {
  const [visitorList, setVisitorList] = useState([] as VisitorParticipation[]);
  
  useEffect(() => {
    const fetchList = async () => {
      const response = await fetchAsync<VisitorParticipation[]>('VisitorParticipation/GetList', null);
      setVisitorList(response);
    };
    fetchList();
  }, []);

  return <>
    <Toaster></Toaster>
    <MemberHeader></MemberHeader>
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>

    <div className="pl-8 pt-4">
      <table className="data-table mt-2">
        <thead>
          <tr>
            <th>uid</th>
            <th>visitor id</th>
            <th>name</th>
            <th>date</th>
          </tr>
        </thead>
        <tbody>
          { visitorList.map((e) => (
            <tr>
              <td>{e.uid}</td>
              <td>{e.visitorId}</td>
              <td>{e.name}</td>
              <td>{ToDateString(e.date!)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </>
}
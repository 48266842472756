import { FC } from "react";
import { getIsAdmin } from "../CookieHandler";
import { getIsLoggedIn } from "../CookieHandler";

export const MemberHeader: FC = () => {
  const isAdmin = getIsAdmin();
  const isLoggedIn = getIsLoggedIn();
  const isSigninPage = window.location.pathname === '/member/sign-in';

  return <div>
    <div className="w-full" style={{ backgroundColor: 'white', borderBottom:'1px solid #e1e1e1' }}>
      <div className="margin-auto text-left">
        <div className="w-full">
          <div className="justify-between inline-flex pt-2 w-full pr-0 sm:pr-8 items-center">
            <div>
              <a href="/member/schedule-board">
                <img className="h-[60px] ml-1 sm:ml-4" src="/images/trimed-logo.png"></img>
              </a>
            </div>
          </div>

          <div className="ml-2 sm:ml-7 mb-2">
            { isLoggedIn && isAdmin && !isSigninPage &&
                <div className="text-gray-600 text-sm">
                  <a className="mr-3" href="/admin/member-list">メンバー</a>
                  <a className="mr-3" href="/admin/training-day-list">練習日</a>
                  <a className="mr-2" href="/admin/visitor-request-list">初参加</a>
                  <a className="mr-2" href="/admin/visitor-participation-list">ビジター</a>
                  <a className="mr-3" href="/admin/participation-history">履歴</a>
                  <a className="mr-2" href="/admin/gym-date">けやき</a>
                </div>
            }
          </div>
          
        </div>
        
      </div>
    </div>
  </div>
}
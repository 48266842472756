import { FC, useEffect, useState } from "react";
import { Header } from "../parts/Header";
import { fetchAsync, fetchWithoutSignAsync } from "../ApiHandler";
import { BoardItem, BoardResponse } from "../Model/BoardItem";
import { unescape } from "querystring";
import { ToDateString } from "../utils/DateUtil";
import { getDayOfWeekString } from "../utils/DateUtil";
import { TrimSeconds } from "../utils/DateUtil";

export const TopPage: FC = () => {
  const [list, setList] = useState(undefined as BoardItem[] | undefined);

  useEffect(() => {
    const fetchList = async () => {
      const list = await fetchWithoutSignAsync<BoardItem[]>('TrainingDay/GetListForVisitor', null);
      setList(list);
    };
    fetchList();
  }, []);

  return <div>
    <Header></Header>
    <div>
      <img src="/images/top-02.jpg" style={{ width: '100%' }}></img>
    </div>

    <div className="margin-auto px-4 box-border" style={{ width: '720px', maxWidth: '100%' }}>
      <h1 className="font-bold mt-12">世田谷バドミントンクラブ CHIROL (チロル)</h1>
      <div className="mt-12 mb-12">
        <div className="sub-title">直近の練習日程</div>
        { list && list!.map((e) => (
          <div>
             {ToDateString(e.trainingDay?.date!)} ({getDayOfWeekString(e.trainingDay?.date!)}) {TrimSeconds(e.trainingDay?.startTime!)} - {TrimSeconds(e.trainingDay?.endTime!)} {e.trainingPlace?.areaName}
          </div>
        ))}
      </div>
      <div className="mb-12">
        <div className="sub-title">活動内容</div>
        <div>2004年から東京都世田谷区で活動している社会人バドミントンサークルです。</div>
      </div>
      <div className="mb-12">
        <div className="sub-title">メンバー</div>
        <div>
          30代の社会人の方が中心です。高校生・大学生や、40代の方もいます。<br/>
          男性と女性の比率は 2:1 ほどです。<br/>
          サークル全体の人数は約40名。毎回15名前後で練習しています。
        </div>
      </div>
      <div className="mb-12">
        <div className="sub-title">練習日時・場所</div>
        <div>
        「土曜日・日曜日・祝日に、世田谷区の小・中学校の体育館で練習をしています。」<br/>
        <br></br>
        千歳烏山・仙川、三軒茶屋、笹塚、上北沢～芦花公園、千歳船橋・祖師ヶ谷大蔵など、京王線・小田急線・世田谷線沿いです。<br/>
        </div>
      </div>

      <div className="mb-12">
        <div className="sub-title">練習当日の流れ</div>
        <div>
          <div className="mb-2">練習前</div>
          1. 練習場所の学校の入り口に集合します。<br/>
          2. 学校敷地内と校門付近は、原付・バイク・自動車の駐輪・駐車ができません。グラウンドなど関係ない場所に立ち入り禁止の学校もあります。また、学校敷地内と校門付近は禁煙です。<br/>
          3. 着替えのあと、ネット張り・カーテン閉め・ストレッチなど準備。<br/>
          4. 初参加メンバーがいるときは自己紹介タイムがあります。<br/>
          5. 準備ができた相手を見つけて基礎打ちをします。<br/>
          6. その後はダブルスの試合です。<br/>
          <div className="mb-2 mt-6">練習後</div>
          1. 練習終了の15分前に試合を止めて、みんなで後片付け・体育館の掃除をします。 <br/>
          2. 最後に次回の練習についてなどの連絡事があります。<br/>
        </div>
      </div>

      <div className="mb-12">
        <div className="sub-title">試合形式</div>
        <div>
          練習の前半は、ランダムに組み合わせを指定してダブルス。後半は組み合わせ自由でダブルスです。<br/>
          <br/>
          コートが空いていれば、各自でサービスや基礎打ちなどの練習ができます。<br/>
          大会が近いメンバーは、ペア固定の試合・シングル戦も調整できます。<br/>
        </div>
      </div>

      <div className="mb-12">
        <div className="sub-title">持参するもの</div>
        <div>
        ・ラケット<br/>
        ・バドミントン用のシューズ<br/>
        ・ウェア<br/>
        ・タオル<br/>
        ・飲み物<br/>
        </div>
      </div>

      <div className="mb-12">
        <div className="sub-title">参加費</div>
        <div>
        4時間練習: 1000円<br/>
        <br/>
        コートが2,3面の体育館で、練習1回あたりの参加費です。入会費・年会費はありません。<br/>
        <br/>
        キャッシュレス推進のため、現金支払いは不可です。参加費はPayPayで送金してください。<br/>
        送金するためには、事前にPayPayで本人確認を済ませておく必要があります。<br/>
        PayPayの送金ができない場合、できるメンバーに現金を渡して、そのメンバー経由で送金してもらいます。<br/>
        <br/>
        着替え、準備の前までに参加費を送金してください。<br/>
        </div>
      </div>

      <div className="mb-6">
        <div className="sub-title">クラブ運営</div>
        <div>
          代表と一部のメンバーによって、練習場所の確保・シャトル準備・会計・ホームページ保守などを行っています。
        </div>
      </div>

      <div className="mt-2 mb-24">
        <a className="underline" href="/join">練習参加時の注意点について</a>
      </div>

    </div>
  </div>
}

import dayjs, { Dayjs } from "dayjs";

export function ToMinutesString(dateStirng: string | undefined) : string {
  if(dateStirng === undefined || dateStirng === '' ) { return '' }
  let date = dayjs(dateStirng);
  if(date.isValid()) {
    return date.format('YYYY-MM-DD HH:mm:ss');
  } else {
    return '';
  }
}


export function ToDateString(dateStirng: string) : string {
  let date = dayjs(dateStirng);
  if(date.isValid()) {
    return date.format('YYYY-M-D');
  } else {
    return '';
  }
}

export function TrimSeconds(timeSpan: string) :string {
  var items = timeSpan.split(':');
  return `${items[0]}:${items[1]}`;
}

const dayOfWeeks = [ '日', '月', '火', '水', '木', '金', '土'];


export function getDayOfWeekString(dateString: string) : string {
  var date = dayjs(dateString);
  return dayOfWeeks[date.day()];
}
import { FC, useState, useEffect } from "react";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import { postAsync } from "../../ApiHandler";
import toast, { Toaster } from "react-hot-toast";
import { saveToken } from "../../CookieHandler";
import { MemberHeader } from "../../parts/MemberHeader";
import { getIsLoggedIn } from "../../CookieHandler";
import { Helmet } from "react-helmet";
import { saveEmail, getEamil } from "../../CookieHandler";

export const SignIn: FC = () => {
  const [isTokenSent, setIsTokenSent] = useState(false);
  const [isDisabled, setIsDiabled] = useState(false);
  const [email, setEmail] = useState('');
  const [tokenNumber, setTokenNumber] = useState('');
  const [tokenId, setTokenId] = useState('');

useEffect(() => {
  var email = getEamil() ?? '';
  setEmail(email);
  const isLoggedIn = getIsLoggedIn();
  if(isLoggedIn) {
    window.location.href = '/member/schedule-board';
  }
}, []);

  async function login() {
    if(isDisabled) { return; }
    setIsDiabled(true);
    var param = { tokenNumber: tokenNumber, tokenGuid: tokenId };
    const response = await postAsync<string>('Account/Login', param);
    if(response.code === 1) {
      saveToken(response.data!);
      window.location.href = '/member/schedule-board';
    } else {
      toast.error(response.message ?? '');
      setIsDiabled(false);
    }
    
  }
  
  async function requestToken() {
    if(isDisabled) { return; }
    setIsDiabled(true);
    var param = { email: email };
    const response = await postAsync<string>('Account/RequestToken', param);
    setIsDiabled(false);
    if(response.code === 1) {
      toast.success("メールを送信しました。メールに記載されている数字を入力してください。")
      setIsTokenSent(true);
      setTokenId(response.data!);
      saveEmail(email!);
    } else {
      toast.error(response.message ?? '');
    }
  }

  return <>
  <MemberHeader></MemberHeader>
  <Toaster></Toaster>
  <Helmet>
    <meta name="robots" content="noindex" />
  </Helmet>
  <div>
    <div className="border rounded margin-auto w-[480px] py-4 px-6 mt-12" style={{ maxWidth: '100%' }}>
      <div className="font-bold margin-auto w-full text-center mb-4">チロルメンバー用 ログイン</div>
      { !isTokenSent && <>
      <div className="text-gray-600 mb-1">メールアドレス</div>
      <input onChange={(e) => setEmail(e.target.value)} value={email} type="text" className="border rounded px-2 w-full py-1"></input>
      <button disabled={isDisabled} onClick={requestToken} className="simple-button w-full py-2 mt-6" style={{ paddingTop: '8px', paddingBottom: '8px' }}>認証コード送信</button>
      </> }
      { isTokenSent && <>
      <div className="text-gray-600 mb-1">認証コード</div>
      <input onChange={(e) => setTokenNumber(e.target.value)} type="text" className="border rounded px-2 w-full py-1" placeholder="6桁の数字" ></input>
      <button disabled={isDisabled} onClick={login} className="simple-button w-full py-2 mt-6" style={{ paddingTop: '8px', paddingBottom: '8px' }}>ログイン</button>
      </> }
    </div>
  </div>
  </>
  
}
import { FC, useState } from "react";

export const Header: FC = () => {
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  return <>
  { mobileMenuVisible &&
  <div id="spmenu" style={{ backgroundColor: '#000000ba', position: 'fixed', top: '64px', width: '100%', zIndex: '2', color: '#fff' }}>
    <a className="block pl-4 py-3" href="/" style={{ borderBottom: '1px solid white' }}>About</a>
    <a className="block pl-4 py-3" href="/join" style={{ borderBottom: '1px solid white' }}>Rules</a>
    <a className="block pl-4 py-3" href="/gallery" style={{ borderBottom: '1px solid white' }}>Gallery</a>
  </div>
  }
  <div>
    <div className="w-full" style={{ zIndex: '1', backgroundColor: 'white', position: "fixed", top: '0', borderBottom:'1px solid #e1e1e1' }}>
      <div className="margin-auto text-left">
        <div className="w-full">
          <div className="justify-between inline-flex pt-2 w-full pr-8 items-center">
            <div>
              <a href="/">
              <img className="h-[60px] ml-4" src="/images/trimed-logo.png"></img>
              </a>
            </div>
            <div className="text-gray-600 pc-menu">
              <a className="mr-5" href="/">About</a>
              <a className="mr-5" href="/join">Rules</a>
              <a className="mr-5" href="/gallery">Gallery</a>
            </div>
            { !mobileMenuVisible &&
            <div onClick={() => setMobileMenuVisible(true)} className="sp-menu hover-button">
              <img style={{ height: '24px' }} src="/images/icon-menu.svg"></img>
            </div>
            }
            { mobileMenuVisible &&
            <div onClick={() => setMobileMenuVisible(false)} className="sp-menu hover-button">
              <img style={{ height: '24px' }} src="/images/icon-close2.svg"></img>
            </div>
            }
          </div>
        </div>
      </div>

    </div>
    <div className="py-6 text-center" style={{ marginTop: '26px '}}>    
    </div>
  </div>
  </>
}
import { FC, useState, useEffect } from "react";
import { postAsync } from "../../ApiHandler";
import { fetchAsync } from "../../ApiHandler";
import { BoardItem } from "../../Model/BoardItem";
import { SelectOption } from "../../Model/SelectOption";
import toast, { Toaster } from "react-hot-toast";
import { Account } from "../../Model/Account";
import { MemberHeader } from "../../parts/MemberHeader";
import { ToMinutesString } from "../../utils/DateUtil";
import { Helmet } from "react-helmet";
import Select from "react-select";
import { Circle } from "../../Model/Circle";

class AddMemberParam {
  email: string = '';
  name: string = '';
}

export const MemberListPage: FC = () => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const [circleDialogVisible, setCircleDialogVisible] = useState(false);
  const [formParam, setFormParam] = useState({} as AddMemberParam);
  const [isDisabled, setIsDisabled] = useState(false);
  const [version, setVersion] = useState(0);
  const [accountList, setAccountList] = useState([] as Account[]);
  const [account, setAccount]  = useState(undefined as Account | undefined);
  const [circleOption, setCircleOption] = useState(undefined as SelectOption | undefined);
  const [circleOptions, setCircleOptions] = useState([] as SelectOption[]);

  function onChabgeValue(name: string, value: string) {
    setFormParam(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  function onSelectAccount(account: Account) {
    setAccount(account);
    setCircleDialogVisible(true);
  }

  async function update() {
    if(isDisabled) { return; }
    setIsDisabled(true);
    const param = {
      accountId: account?.accountId,
      circleId: circleOption?.value
    }
    const response = await postAsync<string>('CircleMember/Update', param);
    setIsDisabled(false);
    if (response.code === 1) {
      toast.success("更新しました");
      setCircleOption(undefined);
      setCircleDialogVisible(false);
      setVersion(version + 1);
    } else {
      toast.error(response.message ?? '');
    }
  }

  async function register() {
    if(isDisabled) { return; }
    setIsDisabled(true);
    const response = await postAsync<string>('AdminAccount/AddAccount', formParam);
    setIsDisabled(false);
    if (response.code === 1) {
      toast.success("登録しました");
      setFormParam({} as AddMemberParam);
      setDialogVisible(false);
      setVersion(version + 1);
    } else {
      toast.error(response.message ?? '');
    }
  }

  useEffect(() => {
    const fetchList = async () => {
      const list = await fetchAsync<Account[]>('AdminAccount/GetList', null);
      setAccountList(list);
    };

    const fetchCircles = async () => {
      const list = await fetchAsync<Circle[]>('Circle/GetList', null);
      var options = [] as SelectOption[];
      for (var item of list) {
        options.push({ label: item.name, value: item.circleId } as SelectOption);
      }
      setCircleOptions(options);
    };
  
    fetchCircles();
    fetchList();
  }, [version]);

  return <>
    { dialogVisible && 
    <div className="bg-white pt-4 second-bg-color px-6 rounded-md second-bg-color input-dialog">
      <div className="mb-4 text-gray-600 text-sm inline-flex w-full justify-between">
        <span>メンバー追加</span>
        <img onClick={(e) => setDialogVisible(false)} style={{ width: '20px' }} className="hover-button" src="/images/icon-close.svg"></img>
      </div>
      <div className="mb-2">
        <input onChange={(e) => onChabgeValue('email', e.target.value)} value={formParam.email} className="w-full simple-input" placeholder="メールアドレス"></input>
      </div>
      <div className="mb-6">
        <input onChange={(e) => onChabgeValue('name', e.target.value)} value={formParam.name} className="w-full simple-input" placeholder="氏名"></input>
      </div>
      <div className="mb-4">
        <button disabled={isDisabled} onClick={register} className="simple-button hover-button">追加</button>
      </div>
    </div>
    }
    
    { circleDialogVisible && 
    <div className="bg-white pt-4 second-bg-color px-6 rounded-md second-bg-color input-dialog" style={{ height: '400px' }}>
      <div className="mb-4 text-gray-600 text-sm inline-flex w-full justify-between">
        <span>メンバー設定</span>
        <img onClick={(e) => setCircleDialogVisible(false)} style={{ width: '20px' }} className="hover-button" src="/images/icon-close.svg"></img>
      </div>
      <div className="mb-6">
        <Select value={circleOption} onChange={(e) => setCircleOption(e!)} options={circleOptions} placeholder="サークル"></Select>
      </div>
      <div className="mb-4">
        <button disabled={isDisabled} onClick={update} className="simple-button hover-button">更新</button>
      </div>
    </div>
    }

    <Toaster></Toaster>
    <MemberHeader></MemberHeader>
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <div className="pl-8 pt-4">
      <div className="inline-flex text-gray-700 items-end">
        <button className="simple-button mr-4" onClick={(e) => setDialogVisible(true)}>メンバー追加</button>
      </div>
      <table className="data-table mt-2">
        <thead>
          <tr>
            <th>Uid</th>
            <th>Account ID</th>
            <th>Email</th>
            <th>Name</th>
            <th>Circle</th>
            <th>Last login date</th>
            <th>Created Date</th>
          </tr>
        </thead>
        <tbody>
          { accountList.map((e) => (
            <tr onClick={(eve) => onSelectAccount(e)}>
              <td>{e.uid}</td>
              <td>{e.accountId}</td>
              <td>{e.email}</td>
              <td>{e.name}</td>
              <td>{e.circleName}</td>
              <td>{ToMinutesString(e.lastLoginDate ?? '')}</td>
              <td>{ToMinutesString(e.createdDate)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </>
}
import { FC, useState, useEffect } from "react";
import { fetchAsync } from "../../ApiHandler";
import { MemberHeader } from "../../parts/MemberHeader";
import { ToMinutesString, ToDateString } from "../../utils/DateUtil";
import { Helmet } from "react-helmet";
import { ParticipationHistory } from "../../Model/ParticipationHistory";

export const ParticipationHistoryPage: FC = () => {
  const [participationList, setParticipationList] = useState([] as ParticipationHistory[]);

  function convertStatus(status: string) : string {
    if (status === 'attend') return '出席';
    else if(status === 'absence') return '欠席';
    else if(status === 'empty') return '未回答';
    else if(status === 'middle') return '途中参加';
    else if(status === 'early') return '早退';
    else return '';
  }

  useEffect(() => {
    const fetchList = async () => {
      const list = await fetchAsync<ParticipationHistory[]>('ParticipationHistory/GetList', null);
      setParticipationList(list);
    };
  
    fetchList();
  }, []);

  return <>
    <MemberHeader></MemberHeader>
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <div className="pl-8 pt-4">
      <table className="data-table mt-2">
        <thead>
          <tr>
            <th>Name</th>
            <th>Date</th>
            <th>Status</th>
            <th>Created Date</th>
          </tr>
        </thead>
        <tbody>
          { participationList.map((e) => (
            <tr>
              <td>{e.name}</td>
              <td>{ToDateString(e.date ?? '')}</td>
              <td>{convertStatus(e.status ?? '')}</td>
              <td>{ToMinutesString(e.createdDate)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </>
}